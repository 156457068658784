import {
  Box,
  Button,
  Container,
  Snackbar,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";

import MuiAlert from "@material-ui/lab/Alert";
import addToMailchimp from "gatsby-plugin-mailchimp";

const useStyles = makeStyles({
  main: {
    background: "#2E2E2E",
    color: "#FFF",
  },
  container: {
    paddingTop: 48,
    paddingBottom: 48,
  },
  textField: {
    background: "#FFF",
  },
  button: {
    backgroundColor: "#8D72C3",
  },
  form: {
    display: "flex",
    paddingTop: 12,
  },
  formSpacer: {
    width: 12,
  },
});

export const NewsLetter = () => {
  const [signUpError, setSignUpError] = useState<string | null>(null);
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Container maxWidth="lg" className={classes.container}>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            console.log("::su");

            addToMailchimp(email, [], undefined)
              .then((data) => {
                if (data.result === "error") {
                  setSignUpError(data.msg);
                } else {
                  setSignUpSuccess(true);
                }
              })
              .catch(() => {
                setSignUpError(
                  "There was an error signing up for our newsletter. Did you already join?"
                );
              });
          }}
        >
          <Typography variant="h5">Never miss an update!</Typography>
          <Typography variant="body1">Join our newsletter</Typography>
          <Box className={classes.form}>
            <TextField
              size="small"
              className={classes.textField}
              variant="filled"
              label="Email Address"
              value={email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
            />
            <Box className={classes.formSpacer} />
            <Button
              variant="contained"
              size="large"
              color="secondary"
              className={classes.button}
              type="submit"
            >
              Sign Up
            </Button>
          </Box>
          <Snackbar
            open={signUpSuccess}
            autoHideDuration={6000}
            onClose={() => {
              setSignUpSuccess(false);
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <MuiAlert severity="success">Thank you for signing up!</MuiAlert>
          </Snackbar>
          <Snackbar
            open={!!signUpError}
            autoHideDuration={6000}
            onClose={() => {
              setSignUpError(null);
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <MuiAlert severity="error">
              <span
                dangerouslySetInnerHTML={{
                  __html: signUpError,
                }}
              ></span>
            </MuiAlert>
          </Snackbar>
        </form>
      </Container>
    </Box>
  );
};
