import React, { useEffect } from "react";

import { ADD_THIS_URL } from "../constants";
import Script from "react-load-script";
import { makeStyles } from "@material-ui/core";

declare const window: any;

interface IShare {
  url: string;
}

const useStyles = makeStyles({
  container: {
    paddingTop: 48,
  },
});

export const Share = (props: IShare) => {
  const classes = useStyles();

  useEffect(() => {
    if (window.addthis) {
      window.addthis.update("share", "url", props.url);
    }
  }, [props.url]);

  const handleAddthisLoaded = () => {
    if (window.addthis) {
      window.addthis.init();
      window.addthis.update("share", "url", props.url);
    }
  };

  return (
    <div className={classes.container}>
      <div className="addthis_inline_share_toolbox"></div>
      <Script url={ADD_THIS_URL} onLoad={handleAddthisLoaded} />
    </div>
  );
};
