import * as React from "react";

import {
  Avatar,
  Box,
  Card,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: `rgba(250,250,250,0.05)`,
    color: "#FFF",
    marginBottom: 48,
    marginTop: 24,
  },
  containerInner: {
    padding: 24,
    display: "flex",
  },
  content: {
    paddingLeft: 24,
  },
  image: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  link: {
    color: "#FFF",
    textDecoration: "none",
  },
}));

export const Author = () => {
  const classes = useStyles();

  return (
    <Card className={classes.container}>
      <Box className={classes.containerInner}>
        <Avatar
          src="/images/tyson.jpeg"
          alt="Tyson Cadenhead"
          className={classes.image}
          variant="circle"
        />
        <Box className={classes.content}>
          <Typography variant="h6">
            Tyson Cadenhead leads the dev team at{" "}
            <a className={classes.link} href="https://vestaboard.com">
              Vestaboard
            </a>
          </Typography>
          <Typography variant="body2">
            He has a passion for Functional Programming, GraphQL, Serverless
            architecture and React. When he's not writing code or working with
            his team, Tyson enjoys drawing, playing guitar, growing vegetables
            and spending time with his wife, two boys and his dog and cat.
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};
