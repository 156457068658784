import * as React from "react";

import { Box, makeStyles } from "@material-ui/core";

import { useEffect } from "react";

interface IComments {
  pageId: string;
  pageUrl: string;
  pageTitle: string;
}

const useStyles = makeStyles({
  box: {
    "& label": {
      color: "#FFF !important",
    },
  },
});

export const Comments = (props: IComments) => {
  const classes = useStyles();

  useEffect(() => {
    (function () {
      // DON'T EDIT BELOW THIS LINE
      var d = document,
        s = d.createElement("script");
      s.src = "https://thenable.disqus.com/embed.js";
      s.setAttribute("data-timestamp", new Date().toString());
      (d.head || d.body).appendChild(s);
    })();
  }, []);

  return (
    <Box className={classes.box}>
      <div id="disqus_thread"></div>
    </Box>
  );
};
