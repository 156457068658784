import * as React from "react";

import { DOMAIN, TITLE } from "../constants";
import { Typography, makeStyles, Box, Container } from "@material-ui/core";

import { Author } from "../components/Author";
import { Comments } from "../components/Comments";
import { Content } from "../components/Content";
import { Header } from "../components/Header";
import { Helmet } from "react-helmet";
import { NewsLetter } from "../components/Newsletter";
import { Share } from "../components/Share";
import { Theme } from "../components/Theme";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import { graphql } from "gatsby";

declare let window: any;

deckDeckGoHighlightElement();

const useStyles = makeStyles({
  hero: {
    background: "#539DBF",
    paddingTop: 50,
    paddingBottom: 50,
  },
  heroInner: {
    display: "flex",
    alignItems: "center",
    color: "#FFF",
  },
  thumbnail: {
    width: "80px",
    borderRadius: "50%",
    background: "rgba(0,0,0,0.25)",
    padding: 12,
    marginRight: 24,
    height: "80px",
  },
  content: {
    "& a": {
      color: "#FFF",
    },
    "& h2": {
      color: "#539DBF",
      marginTop: 48,
    },
    "& h3": {
      color: "#999",
      marginTop: 48,
    },
    "& img": {
      maxWidth: "100%",
    },
  },
});

export default ({ data }) => {
  const classes = useStyles();
  const { markdownRemark: post } = data;

  React.useEffect(() => {
    if (window) {
      setInterval(() => {
        if (window?.hljs) {
          window?.hljs.highlightAll();
        }
      }, 1000);
    }
  }, []);

  return (
    <Theme>
      <main>
        <Helmet>
          <title>
            {post.frontmatter.title} | {TITLE}
          </title>
          <meta name="author" content="Thenable" />
          <meta name="description" content={post.excerpt} />
          <meta property="og:description" content={post.excerpt} />
          <meta
            property="og:title"
            content={`${post.frontmatter.title} | ${TITLE}`}
          />
          <meta name="twitter:description" content={post.excerpt} />
          <meta
            name="twitter:title"
            content={`${post.frontmatter.title} | ${TITLE}`}
          />
          <link
            rel="stylesheet"
            href="//cdnjs.cloudflare.com/ajax/libs/highlight.js/11.3.1/styles/atom-one-dark-reasonable.min.css"
          />
          <script src="//cdnjs.cloudflare.com/ajax/libs/highlight.js/11.3.1/highlight.min.js" />
        </Helmet>
        <Header />
        <Box className={classes.hero}>
          <Container maxWidth="lg">
            <Box className={classes.heroInner}>
              <img
                className={classes.thumbnail}
                src={`/images/logos/${post.frontmatter.image}.png`}
                alt={post.frontmatter.title}
              />
              <Box>
                <Typography variant="h4">{post.frontmatter.title}</Typography>
                <Typography variant="subtitle1">
                  {post.frontmatter.date}
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
        <Content>
          <Typography>
            <div
              dangerouslySetInnerHTML={{ __html: post.html }}
              className={classes.content}
            />
          </Typography>
          <Share url={`${DOMAIN + post.frontmatter.path}`} />
        </Content>
        <NewsLetter />
        <Content>
          <Author />
          <Comments
            pageId={post.id}
            pageUrl={`${DOMAIN + post.frontmatter.path}`}
            pageTitle={post.title}
          />
        </Content>
      </main>
    </Theme>
  );
};

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        title
        path
        date
        hackerNewsId
        image
        published
      }
    }
  }
`;
